import React, { useState } from 'react';
import { Components } from '../../utils/material-ui';
import styles from './styles';
import { Typography } from '@mui/material';
import theme from '../../utils/theme';
import { getDate } from '../../utils/moment';
import { API_HANDLE_ERROR, API_PATCH } from '../../utils/api';
import { useStateValue } from '../../utils/store';
import './index.css';

const { withStyles, Grid, FormControlLabel, Button, Radio, RadioGroup, useMediaQuery } = Components;

const Item = (props) => <Grid item {...props} />;
const Container = (props) => <Grid container {...props} />;

const GroupEditModal = ({ data, getNominated }) => {
  const scrrenSizeSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const [, dispatch] = useStateValue();

  const [travellingStatus, setTravellingStatus] = useState(data?.travelling);

  const handleSubmit = () => {
    API_PATCH(`travelPackage/updateTravelling/${data?._id}`, { travelling: travellingStatus })
      .then((res) => {
        dispatch({
          type: 'ALERT_OPEN',
          severity: 'success',
          isOpen: true,
          label: res.data?.message ?? 'Success'
        });
        getNominated();
      })
      .catch((err) => {
        API_HANDLE_ERROR(err, dispatch);
      });
  };

  return (
    <Container sx={{ width: scrrenSizeSmall ? 'auto' : '100vw' }}>
      <div className="congratulation-screen">
        <div className="congratulation-content">
          <h2>Congratulations!</h2>
          <Typography sx={{ mb: 2 }}>
            You have been selected to {data?.package?.packageId} join this group.
          </Typography>
          <Typography>
            Your event Start Date is {getDate(data?.package?.startDate)} and End Date is
            {` ${getDate(data?.package?.endDate)}`}
          </Typography>
          <Typography>
            Registration Start date is {getDate(data?.package?.registrationStartDate)} and End date
            is {` ${getDate(data?.package?.registrationEndDate)}`}.
          </Typography>
          <Typography></Typography>
          <Typography sx={{ mt: 2 }}>Are you sure you want to travel?</Typography>
          <Item sx={{ display: 'flex', mt: 2 }}>
            <RadioGroup
              row
              aria-label="inform"
              name="inform"
              sx={{ ml: 'auto', mr: 'auto' }}
              value={travellingStatus}
              onChange={(e) => setTravellingStatus(e.target.value)}>
              <FormControlLabel
                value={false}
                control={<Radio style={{ color: theme.palette.primary.main }} />}
                label="No"
              />
              <FormControlLabel
                value={true}
                control={<Radio style={{ color: theme.palette.primary.main }} />}
                label="Yes"
              />
            </RadioGroup>
          </Item>
          <Button
            onClick={() => handleSubmit()}
            variant="outlined"
            style={{
              backgroundColor: theme.palette.primary.main,
              color: 'white',
              marginTop: '10px',
              width: '200px'
            }}>
            Submit
          </Button>
          {/* <button>Continue</button> */}
        </div>
      </div>
    </Container>
  );
};

export default withStyles(styles)(GroupEditModal);
